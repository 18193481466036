import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Tabulator from "tabulator-tables"; //import Tabulator library
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { CognitoGetCurrentUser } from "./loginUtil";
import moment from 'moment'

function TasksFunc(props){


    document.title = "Tasks";

    const [state, setState] = useState({
      data: [],
      projectStaffInitialsArray: []
    });

    let ell = React.useRef();

      async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
            Authorization: authToken,
          },
        });
        try{
        const body = await response.json();
        return body;
      }
      catch{
        return 0
      }
    
        // if (response.status !== 200) {
        //   throw Error(body.message);
        // }
        
      }

      function sendToAWS(url, dataToChange){
        var cognitoUser = CognitoGetCurrentUser();
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log(err);
          } else {
            if (session.isValid()) {
              callBackendAPIWithKey(
                url,
                session.getIdToken().getJwtToken()
              ).then((res) => {
                console.log(res);
    
                if(dataToChange){
                  setState((prevValue) => {
                    return {
                      ...prevValue,
                      [dataToChange]: res,
                    };
                  });
                }
    
              });
            } else {
              console.log(session);
            }
          }
        });
      }

      const reorderStaffInitialsArray = (arrayElement) =>{
        return(arrayElement.initials)
      }

    useEffect(() => {  

        console.log("updating")
        
        let tabulator = new Tabulator(ell, {
            data: state.data, //link data to table
            reactiveData:true, //enable data reactivity
            columns: [
                { title: "Task", field: "task", width: 200, sorter: "string", headerFilter:"input" },
                { title: "Task_id_letter", field: "Task_id_letter", visible: false },
                {
                  title: "Stage",
                  field: "Stage",
                  editor: "select",
                  editorParams: {
                    values: {
                      " ": " ",
                      "0": "0",
                      "1": "1",
                      "2": "2",
                      "3": "3",
                      "4": "4",
                      "5": "5",
                      "6": "6",
                      "7": "7",
                      "8": "8",
                      "9": "9",
                    },
                  },
                },
                {
                  title: "Manager",
                  field: "Task_manager",
                  sorter: "string",
                  width: 200,
                  editor: "select",
                  headerFilter:"input",
                  editorParams: function (cell) {
                    // return get_staff_initials(props.currentProjectNumber);
                    return "CH";
                  },
                },
                {
                  title: "Task notes",
                  field: "Task_notes",
                //   sorter: "string",
                  width: 600,
                  widthGrow: 3,
                  editable: true,
                  editor: "input",
                  formatter: "textarea",
                  headerFilter:"input"
                },
                {
                  title: "Task Red Button",
                  field: "Task_red_button",
                  sorter: "string",
                  editor: "input",
                  headerFilter:"input"
                },
                {
                  title: "Task completed",
                  field: "Task_completed",
                  sorter: "string",
                  editor: "select",
                  editorParams: function (cell) {
                    // return get_team_leader_of_edited_column(cell._cell.row.data['task'].split(" ")[0]);
                    return "CH";
                  },
                },
                {
                  title: "Task verified",
                  field: "Task_verified",
                  sorter: "string",
                  editor: "select",
                  editorParams: function (cell) {
                    // return get_staff_initials(props.currentProjectNumber);
                    return "CH";
                  },
                },
                {
                  title: "Hidden discipline",
                  field: "hidden_discipline",
                  sorter: "string",
                  visible: false,
                },
              ], //define table columns
            layout: "fitColumns",
            groupBy: "hidden_discipline",
            groupStartOpen: false,
            history: true,
            // cellEdited: (cell) => {
            //     //this.tabulator.undo()
            //     if (cell._cell.column.field.includes("date")) {
            //         if (cell._cell.value !== "Invalid date") {
            //           sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagedates?projectNumber=${props.currentProjectNumber}&column=${cell._cell.column.field}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)
            //         }
            //         else {
            //           cell._cell.table.undo();
            //         }
            //     }
            //     else if (cell._cell.column.field.includes("leader") || cell._cell.column.field.includes("staff")) {
            //       sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagestatusorstaff?projectNumber=${props.currentProjectNumber}&column=${cell._cell.column.field}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)
            //     }
            //     else {
            //         // Only automatically set adjacent date cell to today's date if the status cell is set to 'Completed'
            //         if (!cell._cell.row.data[cell._cell.column.field + '_date'] && cell._cell.value === "Completed") {
            //             var date_column = cell._cell.column.field + '_date';
            //             console.log(cell._cell.value)
            //             sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagedates?projectNumber=${props.currentProjectNumber}&column=${date_column}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)
            //         }
            //         sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagestatusorstaff?projectNumber=${props.currentProjectNumber}&column=${cell._cell.column.field}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)
            //         sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getstages?searchTerm=${props.currentProjectNumber}`, "data")
            //         // if (cell._cell.value == "Completed") {
            //         //     $.ajax({
            //         //         type: "POST",    //WebMethods will not allow GET
            //         //         url: 'WorkMAP.aspx/Check_for_incomplete_tasks',
            //         //         contentType: "application/json; charset=utf-8",
            //         //         dataType: 'json',
            //         //         data: '{project_number: "' + current_project_number + '", discipline_letter: "' + cell._cell.row.data['Discipline'].split(" ")[0] + '", Stage: "' + cell._cell.column.definition.title + '"}',
            //         //         success: function (doc) {
            //         //             if (doc.d.length > 2) {
            //         //                 incomplete_tasks_table.setData(doc.d);
            //         //                 $('#Incomplete-tasks-modal').modal('show');
            //         //                 incomplete_tasks_table.redraw();
            //         //             }
            //         //         },
            //         //     });
            //         // }
            //     }
            // }
          }); 
    
      }, [state.data, state.projectStaffInitialsArray]);

      useEffect(() => { 

        console.log("project number changed")
        async function callBackendAPIWithKey(url, authToken) {
            const response = await fetch(url, {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
                Authorization: authToken,
              },
            });
            try{
            const body = await response.json();
            return body;
          }
          catch{
            return 0
          }
        
            // if (response.status !== 200) {
            //   throw Error(body.message);
            // }
            
          }
    
        let cognitoUser = CognitoGetCurrentUser();
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log(err);
          } else {
            if (session.isValid()) {
              console.log("valid session");
              callBackendAPIWithKey(
                `https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/gettasks?searchTerm=${props.currentProjectNumber}`,
                session.getIdToken().getJwtToken()
              ).then((res) => {
                console.log(res);
                setState((prevValue) => {
                  return {
                    ...prevValue,
                    data: res,
                  };
                });
              });
            } else {
              console.log(session);
            }
          }
        });

        cognitoUser.getSession((err, session) => {
            if (err) {
              console.log(err);
            } else {
              if (session.isValid()) {
                callBackendAPIWithKey(
                  `https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getprojectstaffinitials?projectNumber=${props.currentProjectNumber}`,
                  session.getIdToken().getJwtToken()
                ).then((res) => {
                  console.log(res);
      
                  if("data"){
                    setState((prevValue) => {
                      return {
                        ...prevValue,
                        projectStaffInitialsArray: res,
                      };
                    });
                  }
      
                });
              } else {
                console.log(session);
              }
            }
          });
    
      }, [props.currentProjectNumber]);

      return (
        <div
          style={{
            paddingTop: "25px",
            width: "100%",
          }}
          ref={(el) => (ell = el)}
        />
      );
}

export default TasksFunc
