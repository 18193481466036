import React, { useState, useEffect } from "react";
import "react-tabulator/css/tabulator_midnight.css";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import { ReactTabulator } from "react-tabulator";
import  {
  CognitoGetCurrentUser
} from "./loginUtil";

// Tasks pag component using React Tabulator table for store tasks data from workmap db
const Tasks = (props) => {

  document.title = "Tasks";

  const [state, setState] = useState({
    data: [],
  });

  useEffect(() => {
    const fetchData = async () => {
       // event to pass to the Header Navbar menu (for items to render tiled images in the grid format such as project staff, tasks and disciplines etc).
    var cognitoUser = CognitoGetCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else {
        if (session.isValid()) {
          callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/gettasks?searchTerm=${props.currentProjectNumber}`, session.getIdToken().getJwtToken()).then(
            (res) => {
              console.log(res);

              setState((prevValue) => {
                return {
                  ...prevValue,
                  data: res,
                };
              });
            }
          );
        } else {
          console.log(session);
        }
      }
    });
    };

    fetchData();
  }, [props.currentProjectNumber]);

  async function callBackendAPIWithKey(url, authToken) {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
        Authorization: authToken,
      },
    });
    try{
    const body = await response.json();
    return body;
  }
  catch{
    return 0
  }

    // if (response.status !== 200) {
    //   throw Error(body.message);
    // }
    
  }

  function sendToAWS(url, dataToChange){
    var cognitoUser = CognitoGetCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else {
        if (session.isValid()) {
          callBackendAPIWithKey(
            url,
            session.getIdToken().getJwtToken()
          ).then((res) => {
            console.log(res);

            if(dataToChange){
              setState((prevValue) => {
                return {
                  ...prevValue,
                  [dataToChange]: res,
                };
              });
            }

          });
        } else {
          console.log(session);
        }
      }
    });
  }

  const editableColumns = [
    {
      title: "Name",
      field: "name",
      width: 150,
      editor: "textarea",
      formatter: "textarea",
      headerFilter: "input"
    },
    {
      title: "Age",
      field: "age",
      hozAlign: "left",
      formatter: "progress",
      editor: "progress"
    },
    {
      title: "Date Of Birth",
      field: "dob",
      sorter: "date",
      editor: DateEditor,
      editorParams: { format: "MM/DD/YYYY" }
    },
    {
      title: "Passed?",
      field: "passed",
      hozAlign: "center",
      formatter: "tickCross",
      editor: true
    }
  ];

  const data2 = [
    {
      id: 1,
      name: "Oli Bob",
      age: "12",
      color: "red",
      dob: "01/01/1980",
      rating: 5,
      passed: true,
      pets: ["cat", "dog"]
    },
    {
      id: 2,
      name: "Mary May",
      age: "1",
      color: "green",
      dob: "12/05/1989",
      rating: 4,
      passed: true,
      pets: ["cat"]
    },
    {
      id: 3,
      name: "Christine Lobowski",
      age: "42",
      color: "green",
      dob: "10/05/1985",
      rating: 4,
      passed: false
    },
    {
      id: 4,
      name: "Brendon Philips",
      age: "125",
      color: "red",
      dob: "01/08/1980",
      rating: 4.5,
      passed: true
    },
    {
      id: 5,
      name: "Margret Marmajuke",
      age: "16",
      color: "yellow",
      dob: "07/01/1999",
      rating: 4,
      passed: false
    },
    {
      id: 6,
      name: "Van Ng",
      age: "37",
      color: "green",
      dob: "06/10/1982",
      rating: 4,
      passed: true,
      pets: ["dog", "fish"]
    },
    {
      id: 7,
      name: "Duc Ng",
      age: "37",
      color: "yellow",
      dob: "10/10/1982",
      rating: 4,
      passed: true,
      pets: ["dog"]
    }
  ];

  let tasksColumns = [
    { title: "Task", field: "task", width: 200, sorter: "string", headerFilter:"input" },
    { title: "Task_id_letter", field: "Task_id_letter", visible: false },
    {
      title: "Stage",
      field: "Stage",
      editor: "select",
      editorParams: {
        values: {
          " ": " ",
          "0": "0",
          "1": "1",
          "2": "2",
          "3": "3",
          "4": "4",
          "5": "5",
          "6": "6",
          "7": "7",
          "8": "8",
          "9": "9",
        },
      },
    },
    {
      title: "Manager",
      field: "Task_manager",
      sorter: "string",
      width: 200,
      editor: "select",
      headerFilter:"input",
      editorParams: function (cell) {
        // return get_staff_initials(props.currentProjectNumber);
        return "CH";
      },
    },
    {
      title: "Task notes",
      field: "Task_notes",
    //   sorter: "string",
      width: 600,
      widthGrow: 3,
      editor: "textarea",
      formatter: "textarea",
      headerFilter:"input"
    },
    {
      title: "Task Red Button",
      field: "Task_red_button",
      sorter: "string",
      editor: "input",
      headerFilter:"input"
    },
    {
      title: "Task completed",
      field: "Task_completed",
      sorter: "string",
      editor: "select",
      editorParams: function (cell) {
        // return get_team_leader_of_edited_column(cell._cell.row.data['task'].split(" ")[0]);
        return "CH";
      },
    },
    {
      title: "Task verified",
      field: "Task_verified",
      sorter: "string",
      editor: "select",
      editorParams: function (cell) {
        // return get_staff_initials(props.currentProjectNumber);
        return "CH";
      },
    },
    {
      title: "Hidden discipline",
      field: "hidden_discipline",
      sorter: "string",
      visible: false,
    },
  ];

  const options={
      groupBy: "hidden_discipline",
      groupStartOpen: false
  }

  const cellEdited = (cell) => {
    console.log(props.currentProjectNumber)

    //sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatetasks?projectNumber=${props.currentProjectNumber}&column=${cell._cell.column.field}&value=${cell._cell.value}&taskID=${cell._cell.row.data['task'].split(" ")[0]}`)
}

  return (
    <div style={{ paddingTop: `25px` }}>
      <ReactTabulator
        data={state.data}
        columns={tasksColumns}
        tooltips={true}
        layout={"fitData"}
        options={options}
        cellEdited={cellEdited}
      />
    </div>
  );
};
export default Tasks;