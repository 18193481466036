import React, { useState, useEffect } from "react";
import "react-tabulator/css/tabulator_midnight.css";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import { ReactTabulator } from "react-tabulator";
import {
    CognitoGetCurrentUser
} from "./loginUtil";

const MissingLatLon = () => {

    document.title = "Missing Lat/Lon";

    const [state, setState] = useState({
        data: [],
    });

    const refObj2 = React.useRef(null);

    useEffect(() => {
        // event to pass to the Header Navbar menu (for items to render tiled images in the grid format such as project staff, tasks and disciplines etc).
        var cognitoUser = CognitoGetCurrentUser();
        cognitoUser.getSession((err, session) => {
            if (err) {
                console.log(err);
            } else {
                if (session.isValid()) {
                    callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getmissinglatlon`, session.getIdToken().getJwtToken()).then(
                        (res) => {
                            setState((prevValue) => {
                                return {
                                  ...prevValue,
                                    data: res
                                };
                              });
                        }
                    );
                } else {
                    console.log(session);
                }
            }
        });
    }, []);

    async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
                Authorization: authToken,
            },
        });
        try {
            const body = await response.json();
            return body;
        }
        catch {
            return 0
        }

        // if (response.status !== 200) {
        //   throw Error(body.message);
        // }

    }

    function sendToAWS(url, dataToChange) {
        var cognitoUser = CognitoGetCurrentUser();
        cognitoUser.getSession((err, session) => {
            if (err) {
                console.log(err);
            } else {
                if (session.isValid()) {
                    callBackendAPIWithKey(
                        url,
                        session.getIdToken().getJwtToken()
                    ).then((res) => {
                        console.log("success");

                    });
                } else {
                    console.log(session);
                }
            }
        });
    }

    const cellEdited = (cell) => {
        console.log(`UPdate workmap set ${cell._cell.column.field} = '${cell._cell.value}' where project_number = '${cell._cell.row.data.project_number}'`)

        sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatelatlon?field=${cell._cell.column.field}&value=${cell._cell.value}&project_number=${cell._cell.row.data.project_number}`, false)
    }

    const columns = [
        { title: "Project Number", field: "project_number", headerFilter:"input", width: 150 },
        { title: "Project title", headerFilter:"input", field: "project_title" },
        {
            title: "Latitude", field: "latitude", editor: "input", editorParams: {
                search: true,
                elementAttributes: {
                    maxlength: "10", //set the maximum character length of the input element to 10 characters
                }
            }
        },
        {
            title: "Longitude", field: "longitude", editor: "input", editorParams: {
                search: true,
                elementAttributes: {
                    maxlength: "10", //set the maximum character length of the input element to 10 characters
                }
            }
        },
    ];

    const options = {
        pagination: "local",
        paginationSize: 20,
    }

    return (
        <div style={{ paddingTop: "29px"}}>
            <ReactTabulator
                // ref={refObj2}
                data={state.data}
                columns={columns}
                tooltips={true}
                layout={"fitData"}
                cellEdited={cellEdited}
                options={options}
            />
        </div>
    )
}

export default MissingLatLon
