import React, { useState, useEffect } from "react";
import "./App.css";
import Video from "./Video";
import  {
  CognitoGetCurrentUser
} from "./loginUtil";
const CantileverVideos = (props) => {

  const [state, setState] = useState({
    videosGridArray: [],
  });

  document.title = "Videos";

  useEffect(() => {
    console.log("useEffect called");
    const fetchData = async () => {
       // event to pass to the Header Navbar menu (for items to render tiled images in the grid format such as project staff, tasks and disciplines etc).
    var cognitoUser = CognitoGetCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else {
        if (session.isValid()) {
          callBackendAPIWithKey("https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getcantilevervideos", session.getIdToken().getJwtToken()).then(
            (res) => {
              console.log(res);

              setState((prevValue) => {
                return {
                  ...prevValue,
                  videosGridArray: res,
                };
              });
            }
          );
        } else {
          console.log(session);
        }
      }
    });
    };

    fetchData();
  }, []);

  async function callBackendAPIWithKey(url, authToken) {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
        Authorization: authToken,
      },
    });
    const body = await response.json();

    return body;
  }

  const createVideo = (arrayElement) => {
    // creates Entry component and sets props from the input array element (array from AWS lambda response)
    return (
      <Video
        key={arrayElement.value}
        videoUrl={arrayElement.video_url}
        description={arrayElement.label}
        colour={arrayElement.colour}
        type={arrayElement.type}
        currentProject={props.currentProject}
      />
    );
  };

  return (
    <div className="content">
      <div className="sticky-spacer"></div>
      <div className="sticky-content">
        <div>
          <dl className="dictionary">
            {state.videosGridArray.map(createVideo)}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default CantileverVideos;