import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Tabulator from "tabulator-tables"; //import Tabulator library
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { CognitoGetCurrentUser } from "./loginUtil";
import moment from 'moment'

function Stages(props){


    document.title = "Stages";

    const [state, setState] = useState({
      data: [],
      projectStaffInitialsArray: []
    });

    let ell = React.useRef();

    const status_values = {
        "To do": "To do",
        "Urgent": "Urgent",
        "In progress": "In progress",
        "Waiting": "Waiting",
        "Hold": "Hold",
        "Completed": "Completed",
        "Not yet": "Not yet",
        "N/A": "N/A",
        "Could do": "Could do",
      };

      var dateEditor = function (cell, onRendered, success, cancel) {
        //cell - the cell component for the editable cell
        //onRendered - function to call when the editor has been rendered
        //success - function to call to pass the successfuly updated value to Tabulator
        //cancel - function to call to abort the edit and return to a normal cell
    
        //create and style input
        var cellValue = moment(cell.getValue(), "DD/MM/YYYY").format("YYYY-MM-DD"),
          input = document.createElement("input");
    
        input.setAttribute("type", "date");
    
        input.style.padding = "4px";
        input.style.width = "100%";
        input.style.boxSizing = "border-box";
    
        input.value = cellValue;
    
        onRendered(function () {
          input.focus();
          input.style.height = "100%";
        });
    
        function onChange() {
          if (input.value !== cellValue) {
            success(moment(input.value, "YYYY-MM-DD").format("DD/MM/YYYY"));
          } else {
            cancel();
          }
        }
    
        function onChangeDelete() {
          success(null);
        }
    
        //submit new value on blur or change
        input.addEventListener("change", onChange);
        input.addEventListener("blur", onChange);
    
        //submit new value on enter
        input.addEventListener("keydown", function (e) {
          if (e.keyCode === 13) {
            onChange();
          }
    
          if (e.keyCode === 46) {
            onChangeDelete();
          }
    
          if (e.keyCode === 27) {
            cancel();
          }
        });
    
        return input;
      };

      async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
            Authorization: authToken,
          },
        });
        try{
        const body = await response.json();
        return body;
      }
      catch{
        return 0
      }
    
        // if (response.status !== 200) {
        //   throw Error(body.message);
        // }
        
      }

      function sendToAWS(url, dataToChange){
        var cognitoUser = CognitoGetCurrentUser();
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log(err);
          } else {
            if (session.isValid()) {
              callBackendAPIWithKey(
                url,
                session.getIdToken().getJwtToken()
              ).then((res) => {
                console.log(res);
    
                if(dataToChange){
                  setState((prevValue) => {
                    return {
                      ...prevValue,
                      [dataToChange]: res,
                    };
                  });
                }
    
              });
            } else {
              console.log(session);
            }
          }
        });
      }

      function colour_status(cell, formatterParams, onRendered) {
        switch (cell.getValue()) {
          case "Urgent":
            cell.getElement().style.backgroundColor = "red";
            cell.getElement().style.color = "white";
            break;
          case "To do":
            cell.getElement().style.backgroundColor = "steelblue";
            cell.getElement().style.color = "skyblue";
            break;
          case "In progress":
            cell.getElement().style.backgroundColor = "mediumpurple";
            cell.getElement().style.color = "antiquewhite";
            break;
          case "Waiting":
            cell.getElement().style.backgroundColor = "antiquewhite";
            cell.getElement().style.color = "darkgray";
            break;
          case "Completed":
            cell.getElement().style.backgroundColor = "darkseagreen";
            cell.getElement().style.color = "antiquewhite";
            break;
          case "Hold":
            cell.getElement().style.backgroundColor = "antiquewhite";
            cell.getElement().style.color = "black";
            break;
          case "Not yet":
            cell.getElement().style.backgroundColor = "antiquewhite";
            cell.getElement().style.color = "antiquewhite";
            break;
          case "Could do":
            cell.getElement().style.backgroundColor = "skyblue";
            cell.getElement().style.color = "steelblue";
            break;
          case "N/A":
            cell.getElement().style.color = "rgba(0, 0, 0, 0)";
            break;
          default:
            break;
        }
        return cell.getValue();
      }

      function colour_date_from_status(cell, formatterParams, onRendered) {
        var prev_cell_value = cell.getData()[
          cell._cell.column.field.substring(0, 7)
        ];
    
        switch (prev_cell_value) {
          case "Urgent":
            cell.getElement().style.backgroundColor = "red";
            cell.getElement().style.color = "white";
            break;
          case "To do":
            cell.getElement().style.backgroundColor = "steelblue";
            cell.getElement().style.color = "skyblue";
            break;
          case "In progress":
            cell.getElement().style.backgroundColor = "mediumpurple";
            cell.getElement().style.color = "antiquewhite";
            break;
          case "Waiting":
            cell.getElement().style.backgroundColor = "antiquewhite";
            cell.getElement().style.color = "darkgray";
            break;
          case "Completed":
            cell.getElement().style.backgroundColor = "darkseagreen";
            cell.getElement().style.color = "antiquewhite";
            break;
          case "Hold":
            cell.getElement().style.backgroundColor = "antiquewhite";
            cell.getElement().style.color = "black";
            break;
          case "Not yet":
            cell.getElement().style.backgroundColor = "antiquewhite";
            cell.getElement().style.color = "antiquewhite";
            break;
          case "Could do":
            cell.getElement().style.backgroundColor = "skyblue";
            cell.getElement().style.color = "steelblue";
            break;
          default:
            break;
        }
        return cell.getValue();
      }

      const reorderStaffInitialsArray = (arrayElement) =>{
        return(arrayElement.initials)
      }

    useEffect(() => {  

        console.log("updating")
        
        let tabulator = new Tabulator(ell, {
            data: state.data, //link data to table
            reactiveData:true, //enable data reactivity
            columns: [
                {
                  title: "Discipline",
                  field: "Discipline",
                  width: 170,
                  widthGrow: 3,
                  headerSort: false,
                  editable: true,
                  editor: "input"
                },
                {
                  title: "Team leader",
                  field: "Team_leader",
                  widthGrow: 3,
                  editor: "select",
                  headerSort: false,
                  editorParams:{
                    values: function (cell) {
                      return state.projectStaffInitialsArray.map(reorderStaffInitialsArray)
                    },
                  } 
                },
                {
                  title: "Project staff",
                  field: "Project_staff",
                  widthGrow: 3,
                  headerSort: false,
                  editor: "select",
                  editorParams: function (cell) {
                    return state.projectStaffInitialsArray.map(reorderStaffInitialsArray)
                  },
                },
                {
                  title: "Stage 0",
                  field: "Stage_0_header",
                  widthGrow: 3,
                  headerSort: false,
                  columns: [
                    {
                      title: "Status",
                      field: "Stage_0",
                      width: 100,
                      headerSort: false,
                      editor: "select",
                      editorParams: {
                        values: status_values,
                      },
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_status(cell, formatterParams, onRendered);
                      },
                    },
                    {
                      title: "Date",
                      field: "Stage_0_date",
                      editor: dateEditor,
                      widthGrow: 3,
                      headerSort: false,
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_date_from_status(cell, formatterParams, onRendered);
                      },
                    },
                  ],
                },
                {
                  title: "Stage 1",
                  field: "Stage_1_header",
                  widthGrow: 3,
                  columns: [
                    {
                      title: "Status",
                      field: "Stage_1",
                      width: 100,
                      editor: "select",
                      headerSort: false,
                      editorParams: {
                        values: status_values,
                      },
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_status(cell, formatterParams, onRendered);
                      },
                    },
                    {
                      title: "Date",
                      field: "Stage_1_date",
                      editor: dateEditor,
                      widthGrow: 3,
                      headerSort: false,
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_date_from_status(cell, formatterParams, onRendered);
                      },
                    },
                  ],
                },
                {
                  title: "Stage 2",
                  field: "Stage_2_header",
                  widthGrow: 3,
                  columns: [
                    {
                      title: "Status",
                      field: "Stage_2",
                      width: 100,
                      editor: "select",
                      headerSort: false,
                      editorParams: {
                        values: status_values,
                      },
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_status(cell, formatterParams, onRendered);
                      },
                    },
                    {
                      title: "Date",
                      field: "Stage_2_date",
                      editor: dateEditor,
                      widthGrow: 3,
                      headerSort: false,
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_date_from_status(cell, formatterParams, onRendered);
                      },
                    },
                  ],
                },
                {
                  title: "Stage 3",
                  field: "Stage_3_header",
                  widthGrow: 3,
                  columns: [
                    {
                      title: "Status",
                      field: "Stage_3",
                      width: 100,
                      headerSort: false,
                      editor: "select",
                      editorParams: {
                        values: status_values,
                      },
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_status(cell, formatterParams, onRendered);
                      },
                    },
                    {
                      title: "Date",
                      field: "Stage_3_date",
                      editor: dateEditor,
                      widthGrow: 3,
                      headerSort: false,
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_date_from_status(cell, formatterParams, onRendered);
                      },
                    },
                  ],
                },
                {
                  title: "Stage 4",
                  field: "Stage_4_header",
                  widthGrow: 3,
                  columns: [
                    {
                      title: "Status",
                      field: "Stage_4",
                      width: 100,
                      headerSort: false,
                      editor: "select",
                      editorParams: {
                        values: status_values,
                      },
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_status(cell, formatterParams, onRendered);
                      },
                    },
                    {
                      title: "Date",
                      field: "Stage_4_date",
                      editor: dateEditor,
                      widthGrow: 3,
                      headerSort: false,
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_date_from_status(cell, formatterParams, onRendered);
                      },
                    },
                  ],
                },
                {
                  title: "Stage 5",
                  field: "Stage_5_header",
                  widthGrow: 3,
                  headerSort: false,
                  columns: [
                    {
                      title: "Status",
                      field: "Stage_5",
                      width: 100,
                      headerSort: false,
                      editor: "select",
                      editorParams: {
                        values: status_values,
                      },
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_status(cell, formatterParams, onRendered);
                      },
                    },
                    {
                      title: "Date",
                      field: "Stage_5_date",
                      editor: dateEditor,
                      widthGrow: 3,
                      headerSort: false,
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_date_from_status(cell, formatterParams, onRendered);
                      },
                    },
                  ],
                },
                {
                  title: "Stage 6",
                  field: "Stage_6_header",
                  widthGrow: 3,
                  columns: [
                    {
                      title: "Status",
                      field: "Stage_6",
                      headerSort: false,
                      width: 100,
                      widthGrow: 3,
                      editor: "select",
                      editorParams: {
                        values: status_values,
                      },
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_status(cell, formatterParams, onRendered);
                      },
                    },
                    {
                      title: "Date",
                      field: "Stage_6_date",
                      editor: dateEditor,
                      widthGrow: 3,
                      headerSort: false,
                      formatter: function (cell, formatterParams, onRendered) {
                        return colour_date_from_status(cell, formatterParams, onRendered);
                      },
                    },
                  ],
                },
              ], //define table columns
            layout: "fitColumns",
            history: true,
            cellEdited: (cell) => {
                //this.tabulator.undo()
                if (cell._cell.column.field.includes("date")) {
                    if (cell._cell.value !== "Invalid date") {
                      sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagedates?projectNumber=${props.currentProjectNumber}&column=${cell._cell.column.field}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)
                    }
                    else {
                      cell._cell.table.undo();
                    }
                }
                else if (cell._cell.column.field.includes("leader") || cell._cell.column.field.includes("staff")) {
                  sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagestatusorstaff?projectNumber=${props.currentProjectNumber}&column=${cell._cell.column.field}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)
                }
                else {
                    // Only automatically set adjacent date cell to today's date if the status cell is set to 'Completed'
                    if (!cell._cell.row.data[cell._cell.column.field + '_date'] && cell._cell.value === "Completed") {
                        var date_column = cell._cell.column.field + '_date';
                        console.log(cell._cell.value)
                        sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagedates?projectNumber=${props.currentProjectNumber}&column=${date_column}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)
                    }
                    sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagestatusorstaff?projectNumber=${props.currentProjectNumber}&column=${cell._cell.column.field}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)
                    sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getstages?searchTerm=${props.currentProjectNumber}`, "data")
                    // if (cell._cell.value == "Completed") {
                    //     $.ajax({
                    //         type: "POST",    //WebMethods will not allow GET
                    //         url: 'WorkMAP.aspx/Check_for_incomplete_tasks',
                    //         contentType: "application/json; charset=utf-8",
                    //         dataType: 'json',
                    //         data: '{project_number: "' + current_project_number + '", discipline_letter: "' + cell._cell.row.data['Discipline'].split(" ")[0] + '", Stage: "' + cell._cell.column.definition.title + '"}',
                    //         success: function (doc) {
                    //             if (doc.d.length > 2) {
                    //                 incomplete_tasks_table.setData(doc.d);
                    //                 $('#Incomplete-tasks-modal').modal('show');
                    //                 incomplete_tasks_table.redraw();
                    //             }
                    //         },
                    //     });
                    // }
                }
            }
          }); 
    
      }, [state.data, state.projectStaffInitialsArray]);

      useEffect(() => {  
        async function callBackendAPIWithKey(url, authToken) {
            const response = await fetch(url, {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
                Authorization: authToken,
              },
            });
            try{
            const body = await response.json();
            return body;
          }
          catch{
            return 0
          }
        
            // if (response.status !== 200) {
            //   throw Error(body.message);
            // }
            
          }
    
        let cognitoUser = CognitoGetCurrentUser();
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log(err);
          } else {
            if (session.isValid()) {
              console.log("valid session");
              callBackendAPIWithKey(
                `https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getstages?searchTerm=${props.currentProjectNumber}`,
                session.getIdToken().getJwtToken()
              ).then((res) => {
                console.log(res);
                setState((prevValue) => {
                  return {
                    ...prevValue,
                    data: res,
                  };
                });
              });
            } else {
              console.log(session);
            }
          }
        });

        cognitoUser.getSession((err, session) => {
            if (err) {
              console.log(err);
            } else {
              if (session.isValid()) {
                callBackendAPIWithKey(
                  `https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getprojectstaffinitials?projectNumber=${props.currentProjectNumber}`,
                  session.getIdToken().getJwtToken()
                ).then((res) => {
                  console.log(res);
      
                  if("data"){
                    setState((prevValue) => {
                      return {
                        ...prevValue,
                        projectStaffInitialsArray: res,
                      };
                    });
                  }
      
                });
              } else {
                console.log(session);
              }
            }
          });
    
      }, [props.currentProjectNumber]);

      return (
        <div
          style={{
            paddingTop: "25px",
            width: "100%",
          }}
          ref={(el) => (ell = el)}
        />
      );
}

export default Stages
