import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
// import "./Myob.css";

function Myob(props) {

  document.title="WorkMAP - MYOB"

  const [state, setState] = useState({
    projectSwiperArray: [],
  });

  async function callBackendAPI(url) {
    const response = await fetch(url);
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message);
    }
    return body;
  }

  async function callBackendAPIWithKey(url, authToken) {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
        Authorization: authToken,
      },
    });
    const body = await response.json();

    // if (response.status !== 200) {
    //   throw Error(body.message);
    // }
    return body;
  }

  function getNewValues(data) {
    console.log(data);
    return data;
  }

  const loadOptions = (inputValue, callback) => {
    callBackendAPIWithKey(
      "http://120.150.206.144:8000/node/express/myapp/getInvoiceData?searchTerm=" + inputValue, "XXX"
    ).then((res) => {
      console.log(res)
      setState((prevValue) => {
        return {
          ...prevValue,
          projectSwiperArray: res.express,
        };
      });
      setTimeout(() => {
        callback(
          getNewValues(
            res.express.map((x) => ({
              label:
                x.dInvoiceNumber +
                " " +
                x.dName +
                " ($" +
                x.dTotalinclGST +
                ")",
              value:
                x.dInvoiceNumber +
                " " +
                x.dName +
                " ($" +
                x.dTotalinclGST +
                ")",
            }))
          )
        );
      }, 50);
    });
  };

  return (
    <div
      className="centred"
    >
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions
        placeholder="Search for invoices..."
        loadOptions={loadOptions}
      />
    </div>
  );
}

export default Myob;
