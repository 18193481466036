import React from "react";
import { ContextMenuTrigger } from "react-contextmenu";

function ThumbnailEntry(props) {
  return (
    <div className="swiper-slide swiper-slide-auto">
      
      <ContextMenuTrigger id="same_unique_identifier2">
      <img className="swiper-slide-image"
        src={props.imageUrl}
        alt=""
        height={100}
        width={130}
        projectnumber={props.projectNumber}
        onContextMenu={props.onRightClick}
      />
      <div className="centered-text">{props.projectNumber.split(" ")[0]}</div>
      </ContextMenuTrigger>
    </div>
  );
}

export default ThumbnailEntry;
