import React, { useState, useEffect } from "react";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator_midnight.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';
import {
    CognitoGetCurrentUser
} from "./loginUtil";

const TabulatorTable = (props) => {

    const refObj2 = React.useRef(null);
    

    const [state, setState] = useState({
        data: [],
    });

    async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
                Authorization: authToken,
            },
        });
        try {
            const body = await response.json();
            return body;
        }
        catch {
            return 0
        }

    }

    const columns = [
        { title: "Username", field: "username" },
        { title: "Login time (UTC)", field: "time_accessed" },
    ];

    useEffect(() => {
        // event to pass to the Header Navbar menu (for items to render tiled images in the grid format such as project staff, tasks and disciplines etc).
        var cognitoUser = CognitoGetCurrentUser();
        cognitoUser.getSession((err, session) => {
            if (err) {
                console.log(err);
            } else {
                if (session.isValid()) {
                    callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getradialtestuserslogins`, session.getIdToken().getJwtToken()).then(
                        (res) => {
                            setState((prevValue) => {
                                return {
                                  ...prevValue,
                                    data: res
                                };
                              });
                        }
                    );
                } else {
                    console.log(session);
                }
            }
        });
    }, []);

    return (
        <div style={{margin: "0 auto", paddingTop: "29px" }}>
            <ReactTabulator ref={refObj2} columns={columns} data={state.data} />
        </div>
    )
}
export default TabulatorTable
