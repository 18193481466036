import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Tabulator from "tabulator-tables"; //import Tabulator library
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { CognitoGetCurrentUser } from "./loginUtil";
import moment from 'moment'
import Select from "react-select"

function Todos(props){


    document.title = "Tasks";

    const [state, setState] = useState({
      data: [],
      projectStaffInitialsArray: [],
      staffObject: [],
      currentStaffInitials: localStorage.getItem("currentStaffInitials") ? localStorage.getItem("currentStaffInitials") : "a"
    });

    let ell = React.useRef();

      async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
            Authorization: authToken,
          },
        });
        try{
        const body = await response.json();
        return body;
      }
      catch{
        return 0
      }
    
        // if (response.status !== 200) {
        //   throw Error(body.message);
        // }
        
      }

      function sendToAWS(url, dataToChange){
        var cognitoUser = CognitoGetCurrentUser();
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log(err);
          } else {
            if (session.isValid()) {
              callBackendAPIWithKey(
                url,
                session.getIdToken().getJwtToken()
              ).then((res) => {
                console.log(res);
    
                if(dataToChange){
                  setState((prevValue) => {
                    return {
                      ...prevValue,
                      [dataToChange]: res,
                    };
                  });
                }
    
              });
            } else {
              console.log(session);
            }
          }
        });
      }

      const reorderStaffInitialsArray = (arrayElement) =>{
        return(arrayElement.initials)
      }

    useEffect(() => {  

        console.log("updating")
        
        let tabulator = new Tabulator(ell, {
            data: state.data, //link data to table
            reactiveData:true, //enable data reactivity
            tooltips: true,
            columns: [
              { title: "Project", field: "project", width: 400, sorter: "string", headerFilter: "input" },
                { title: "Task", field: "task", width: 200, sorter: "string", headerFilter:"input" },
                { title: "Task_id_letter", field: "Task_id_letter", visible: false },
                {
                  title: "Stage",
                  field: "Stage",
                  editor: "select",
                  editorParams: {
                    values: {
                      " ": " ",
                      "0": "0",
                      "1": "1",
                      "2": "2",
                      "3": "3",
                      "4": "4",
                      "5": "5",
                      "6": "6",
                      "7": "7",
                      "8": "8",
                      "9": "9",
                    },
                  },
                },
                {
                  title: "Manager",
                  field: "Task_manager",
                  sorter: "string",
                  width: 200,
                  headerFilter:"input",
                },
                {
                  title: "Task notes",
                  field: "Task_notes",
                //   sorter: "string",
                  width: 600,
                  widthGrow: 3,
                  // formatter: "textarea",
                  headerFilter:"input"
                },
                {
                  title: "Task Red Button",
                  field: "Task_red_button",
                  sorter: "string",
                  editor: "input",
                  headerFilter:"input"
                },
                {
                  title: "Task completed",
                  field: "Task_completed",
                  sorter: "string",
                  editor: "select",
                  editorParams:{
                    values:  cell => {
                      console.log([cell._cell.row.data['Task_manager']])
                      console.log(state.projectStaffInitialsArray.map(reorderStaffInitialsArray))
                      return [cell._cell.row.data['Task_manager']]
                    },
                  } 
                },
                {
                  title: "Task verified",
                  field: "Task_verified",
                  sorter: "string",
                  editor: "select",
                  editorParams: function (cell) {
                    // return get_staff_initials(props.currentProjectNumber);
                    return "CH";
                  },
                },
                {
                  title: "Hidden discipline",
                  field: "hidden_discipline",
                  sorter: "string",
                  visible: false,
                },
              ], //define table columns
            layout: "fitColumns",
            pagination: "local",
            paginationSize: 20,
            // groupBy: "hidden_discipline",
            // groupStartOpen: false,
            history: true,
            cellEdited: (cell) => {

                  sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatetasks?projectNumber=${cell._cell.row.data['project'].split(" ")[0]}&column=${cell._cell.column.field}&value=${cell._cell.value}&taskID=${cell._cell.row.data['task'].split(" ")[0]}`)

              // console.log(cell._cell.row.data['project'].split(" ")[0])
              // console.log(cell._cell.column.field)
              // console.log(cell._cell.value)
              // console.log(cell._cell.row.data['task'].split(" ")[0])

            }
          }); 
    
      }, [state.data, state.projectStaffInitialsArray]);

      useEffect(() => { 

        console.log("project number changed")
        async function callBackendAPIWithKey(url, authToken) {
            const response = await fetch(url, {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
                Authorization: authToken,
              },
            });
            try{
            const body = await response.json();
            return body;
          }
          catch{
            return 0
          }
        
            // if (response.status !== 200) {
            //   throw Error(body.message);
            // }
            
          }
    
        let cognitoUser = CognitoGetCurrentUser();
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log(err);
          } else {
            if (session.isValid()) {
              // console.log("valid session");
              callBackendAPIWithKey(
                `https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getstafftodolist?searchTerm=${state.currentStaffInitials}`,
                session.getIdToken().getJwtToken()
              ).then((res) => {
                console.log(res);
                setState((prevValue) => {
                  return {
                    ...prevValue,
                    data: res,
                  };
                });
              });
            } else {
              console.log(session);
            }
          }
        });

        cognitoUser.getSession((err, session) => {
            if (err) {
              console.log(err);
            } else {
              if (session.isValid()) {
                callBackendAPIWithKey(
                  `https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getprojectstaffinitials?projectNumber=${props.currentProjectNumber}`,
                  session.getIdToken().getJwtToken()
                ).then((res) => {
                  console.log(res);
      
                  if("data"){
                    setState((prevValue) => {
                      return {
                        ...prevValue,
                        projectStaffInitialsArray: res,
                      };
                    });
                  }
      
                });
              } else {
                console.log(session);
              }
            }
          });
    
      }, [state.currentStaffInitials]);

  useEffect(() => {

    console.log("project number changed")
    async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
            Authorization: authToken,
          },
        });
        try{
        const body = await response.json();
        return body;
      }
      catch{
        return 0
      }
    
        // if (response.status !== 200) {
        //   throw Error(body.message);
        // }
        
      }

    let cognitoUser = CognitoGetCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else {
        if (session.isValid()) {
          console.log("valid session");
          callBackendAPIWithKey(
            `https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getallstaff`,
            session.getIdToken().getJwtToken()
          ).then((res) => {
            console.log(res);
            setState((prevValue) => {
              return {
                ...prevValue,
                staffObject: res,
              };
            });
          });
        } else {
          console.log(session);
        }
      }
    });


  }, []);

  const handleStaffChange = selectedOption => {
    console.log(`Option selected:`, selectedOption);
    setState((prevValue) => {
      return {
        ...prevValue,
        currentStaffInitials: selectedOption.value
      };
    });

    localStorage.setItem("currentStaffInitials", selectedOption.value)

  };



  return (
    <div style={{
      paddingTop: "25px",
      width: "100%",
    }}>
      <Select
        options={state.staffObject}
        key={5}
        value={state.staffObject.find(option => option.value === state.currentStaffInitials)}
        // value={state.currentStaffInitials}
        placeholder="Select staff member..."
        onChange={handleStaffChange}
      />
      <div
        style={{
          paddingTop: "25px",
          width: "100%",
        }}
        ref={(el) => (ell = el)}
      />
    </div>
  );
}

export default Todos