import React from 'react'
import S3FileUpload from 'react-s3';

const AWSS3Docs = () => {

    var config = {
        bucketName: "radial-images",
        dirName: "documents",
        region: "ap-southeast-2",
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    };
    const handleDragover = (e) => {

        e.persist()
        e.preventDefault()
    };

    const handleDrop = (e) => {


        // Prevent default behavior (Prevent file from being opened)
        e.preventDefault();

        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < e.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (e.dataTransfer.items[i].kind === "file") {
                    var file = e.dataTransfer.items[i].getAsFile();
                    console.log("... file[" + i + "].name = " + file.name);
                    S3FileUpload.uploadFile(file, config)
                        .then((data) => {
                            console.log(data)
                            alert("Here is a link to your pdf: " + data.location)
                        })
                        .catch((err) => console.error(err));
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (var i = 0; i < e.dataTransfer.files.length; i++) {
                console.log('file[' + i + '].name = ' + e.dataTransfer.files[i].name);
                S3FileUpload.uploadFile(file, config)
                    .then((data) => console.log(data))
                    .catch((err) => console.error(err));
            }
        }
    };
    return (
        <div onDrop={handleDrop} onDragOver={handleDragover} ondr className="content-drop-zone" style={{ height: "80vh", backgroundColor: "grey", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "30px" }}>
            <br />Drop your pdfs here...

        </div>
    )
}

export default AWSS3Docs
