import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import * as moment from "moment";
import "./FullCalendarObject.css";

// must manually import the stylesheets for each plugin
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

export default class FullCalendarObject extends React.Component {
  calendarComponentRef = React.createRef();

  state = {
    calendarWeekends: true,
    calendarEvents: [],
    cursor: "wait"
  };

  render() {
    document.title= "WorkMAP - Calendar"
    return (
      <div className="demo-app">
        {/* <div className="demo-app-top"> */}
        {/* <button onClick={this.toggleWeekends}>toggle weekends</button>&nbsp;
        <button onClick={this.gotoPast}>go to a date in the past</button>
        &nbsp; (also, click a date/time to add an event) */}
        <b>Task manager</b>
        <select placeholder="Select me" style={{ width: "270px" }} onChange={this.handleSelectChange}>
          <option value="DSH">Damian Hadley</option>
          <option value="ADS">Adam Cameron</option>
          <option value="MWM">Marley Meemeduma</option>
          <option value="AJM">Alex Montgomery</option>
          <option value="TLT">Alex Montgomery</option>
          <option value="MTP">Mark Phillips</option>
          <option value="TAS">Ted Skinner</option>
          <option value="LSK">Layla Kia</option>
          <option value="GMH">Georgia Hawkes</option>
          <option value="KLC">Kerrie Cant</option>
        </select>
        <b>  Project staff</b>
        <select placeholder="Select me" style={{ width: "270px" }}>
          <option value="DSH">Damian Hadley</option>
          <option value="ADS">Adam Cameron</option>
          <option value="MWM">Marley Meemeduma</option>
          <option value="AJM">Alex Montgomery</option>
          <option value="TLT">Alex Montgomery</option>
          <option value="MTP">Mark Phillips</option>
          <option value="TAS">Ted Skinner</option>
          <option value="LSK">Layla Kia</option>
          <option value="GMH">Georgia Hawkes</option>
          <option value="KLC">Kerrie Cant</option>
        </select>

        {/* </div> */}
        <div className="demo-app-calendar">
          <FullCalendar
            defaultView="dayGridMonth"
            header={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            ref={this.calendarComponentRef}
            weekends={this.state.calendarWeekends}
            events={this.state.calendarEvents}
            dateClick={this.handleDateClick}
            editable={true}
            eventDrop={this.handleDrop}
            height={window.innerHeight - 300}
            firstDay={1}
            eventLongPressDelay={300}
            selectLongPressDelay={300}
          />
        </div>
      </div>
    );
  }

  async componentDidMount() {
    try {
      const response = await fetch(`http://120.150.206.144:8000/node/express/myapp/getCalendarEvents`);
      const json = await response.json();
      console.log(json)
      this.setState({ calendarEvents: json.body });
    } catch (error) {
      console.log(error);
    }
  }

  callBackendAPI = async url => {
    const response = await fetch(url);
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message);
    }
    return body;
  };

  toggleWeekends = () => {
    this.setState({
      // update a property
      calendarWeekends: !this.state.calendarWeekends
    });
  };

  handleSelectChange = (event) => {
    console.log(event.target.options[event.target.selectedIndex].text)
    //console.log(e.target.value)
  };

  gotoPast = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
    console.log(calendarApi);
  };

  handleDrop = e => {
    console.log(e.event.extendedProps);

    fetch("/Cantilever/sql/updateEvent", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        eventTitle: e.event.title,
        eventStart: moment(e.event.start).format("YYYY-MM-DD"),
        eventStage: e.event.extendedProps.stage,
        eventDisciplineLetter: e.event.extendedProps.discipline_letter
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log("Success:", data);
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  handleDateClick = arg => {
    // this.setState({
    //   // add new event data
    //   calendarEvents: this.state.calendarEvents.concat({
    //     // creates a new array
    //     title: "New Event",
    //     start: arg.date,
    //     allDay: arg.allDay
    //   })
    // });
  };
}
