import React, { useState } from "react";
import "./App.css";
import Entry from "./Entry";
import  {
  CognitoGetCurrentUser
} from "./loginUtil";
import { DragDropContext } from 'react-beautiful-dnd';
const About = (props) => {

  document.title = "About";

  const aboutArray=[{photo_url: "https://radial-images.s3-ap-southeast-2.amazonaws.com/WorkMAP-images/purpose.jpg"},
  {photo_url: "https://radial-images.s3-ap-southeast-2.amazonaws.com/WorkMAP-images/Values.jpg"},
  {photo_url: "https://radial-images.s3-ap-southeast-2.amazonaws.com/WorkMAP-images/mantra.jpg"}];

  const createEntry = (arrayElement) => {
    // creates Entry component and sets props from the input array element (array from AWS lambda response)
    return (
      <Entry
        onRightClick={props.OnRightClick}
        key={arrayElement.value}
        imageUrl={arrayElement.photo_url}
        description={arrayElement.label}
        architect={arrayElement.architect}
        colour={arrayElement.colour}
        type={arrayElement.type}
        handleMenuClick={props.handleMenuClick}
        currentProject={props.currentProject}
      />
    );
  };

  return (
    <div className="content">
      <div className="sticky-spacer"></div>
      <div className="sticky-content">
        <div>
          <dl className="dictionary">
            {aboutArray.map(createEntry)}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default About;