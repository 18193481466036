import React from 'react';
import logo from './logo.svg';
import "swiper/css/swiper.min.css";
import './App.css';
import Swiper from "react-id-swiper";
import ThumbnailEntry from "./ThumbnailEntry";
import { ContextMenu, MenuItem} from "react-contextmenu";
import  {
  CognitoGetCurrentUser
} from "./loginUtil";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSprayCan } from '@fortawesome/free-solid-svg-icons'


const Footer = (props) => {
    if (!props.isLoggedin) return null;

    const params = {
        spaceBetween: 50,
        slidesPerView: window.innerWidth / 200,
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 30,
          modifier: 1,
          slideShadows: false,
        },
        freeMode: true,
        style: {
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
        },
        on: {
          click: function () {



            try {
              if (!this.clickedSlide.firstElementChild.firstElementChild.getAttribute("projectnumber")) {
                console.log("top10 clicked on");
                props.handleTop10JobsChangeFromThumbnail(props.showTop10JobsToggle)
              } else {
                console.log(this.clickedSlide.firstElementChild.firstElementChild.getAttribute("projectnumber"))
                props.handleCurrentProjectChangeFromThumbnail(this.clickedSlide.firstElementChild.firstElementChild.getAttribute("projectnumber"));
              }
            } catch {

            }

          },
        },
      };
      function createThumbnailEntry(image) {
        return (
          <ThumbnailEntry
            key={image.value}
            projectNumber={image.label}
            imageUrl={image.photo_url}
            onRightClick={props.handleSwiperRightClick}
          />
        );
      }

      const handleContextMenuSwiperOpenJobFileClick = () => {
        // when user right-clicks on a project tile (and opens the context menu), sets the current right-clicked-job to its job number, in case the user wants to open this job file via the context menu

        var cognitoUser = CognitoGetCurrentUser();
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log(err);
          } else {
            if (session.isValid()) {
              callBackendAPIWithKey("https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/saveprojectnumbertoopenfolder?username=" + props.loggedInUser + "&project_number=" + props.currentSwiperRightClickedJob, session.getIdToken().getJwtToken()).then(
                (res) => {
                  console.log(res);
                }
              );
            } else {
              console.log(session);
            }
          }
        });
      }

      async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
            Authorization: authToken,
          },
        });
        const body = await response.json();
    
        // if (response.status !== 200) {
        //   throw Error(body.message);
        // }
        return body;
      }

      const top10Element = [{value: "top10", label: "", photo_url: "https://radial-images.s3-ap-southeast-2.amazonaws.com/WorkMAP-images/Top_10.jpg", type: "top10"}];

  return (
    <div className="fixed-footer">
      <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand name="projectTitle" href="#home">

      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {/* <Nav.Link onClick={handleLibraryClick}>Library</Nav.Link> */}
          <Nav.Link>
          <FontAwesomeIcon onClick={() => alert("clicked on")} icon={faSprayCan} />
          </Nav.Link>
          <Nav.Link onClick={(event) => props.handleShowMoreOrLessChangeFromFooter(event.target.innerHTML)}>{props.showMoreOrLessOnCards}</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
      {/* <div>
      <div id="show-more-or-less" style={{ textAlign:"right", paddingRight: "20px", marginBottom: "-15px"}}>{props.showMoreOrLessOnCards}</div>
      </div> */}
      
      {/* <hr style={{backgroundColor: "#ccc"}}></hr> */}
    <Swiper {...params} shouldSwiperUpdate>
      {top10Element.concat(props.top10SwiperArray,props.projectsSwiperArray).map(createThumbnailEntry)}
    </Swiper>
    <ContextMenu id="same_unique_identifier2">
        <MenuItem data={{ foo: "bar" }} onClick={() => handleContextMenuSwiperOpenJobFileClick()}>
          Open project file in explorer
        </MenuItem>
        <MenuItem data={{ foo: "bar" }} >
          ContextMenu Item 2
        </MenuItem>
        <MenuItem divider />
        <MenuItem data={{ foo: "bar" }}>
          ContextMenu Item 3
        </MenuItem>
      </ContextMenu>
  </div>
  );
}
export default Footer; 