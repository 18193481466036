import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import "./Myob.css";

function ProjectStaff(props) {

  document.title="WorkMAP - Staff"

  const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k}`,
    content: `item ${k}`,
    photoUrl: "https://www.cantileverengineers.com.au//images/staff/1542074108-staff-website-6.jpg"
  }));
  
  const staffArray =[
    { id: "item-1", photoUrl: "https://www.cantileverengineers.com.au//images/staff/1542074108-staff-website-6.jpg"},
    {id: "item-8", photoUrl: "https://www.cantileverengineers.com.au//images/staff/1542074197-staff-website-3.jpg"},
    {id: "item-3", photoUrl: "https://www.cantileverengineers.com.au//images/staff/1542074121-staff-website-2.jpg"},
    {id: "item-9", photoUrl: "https://www.cantileverengineers.com.au//images/staff/1552351522-Georgia-cvready.jpg"},
    {id: "item-10", photoUrl: "https://www.cantileverengineers.com.au//images/staff/1542074137-staff-website-7.jpg"},]

  const [state, setState] = useState({
    projectSwiperArray: [],
    items: staffArray
  });

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      state.items,
      result.source.index,
      result.destination.index
    );


    setState((prevValue) => {
      return {
        ...prevValue,
        items: items,
      };
    });
  }

  const grid = 50;

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    display: "flex",
    paddingTop: 50,
    padding: grid,
    overflow: "auto"
  });



// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    margin: `0 ${grid}px 0 0`,
    borderRadius: "50%",
  
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            {state.items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <img
                  height="150px"
                   src={item.photoUrl} alt=""
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    
                    {/* {item.content} */}
                  </img>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
        {/* <div><img height="50" src="https://www.cantileverengineers.com.au//images/staff/1542074108-staff-website-6.jpg" alt="" /></div> */}
      </Droppable>
    </DragDropContext>
  );
}

export default ProjectStaff;
