import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

let poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID, // Your user pool id here
  ClientId: process.env.REACT_APP_CLIENT_ID, // Your client id here
};

let userPool = new CognitoUserPool(poolData);

const GetCognitoUserPool = () => {
  let userPool = new CognitoUserPool(poolData);
  return userPool
}

// Log into using cognito user pool cridentials
const CognitoLogin = async (username, password) => {
  return new Promise((resolve, reject) => {
    var authenticationData = {
      Username: username,
      Password: password,
    };

    var authenticationDetails = new AuthenticationDetails(authenticationData);

    var userData = {
      Username: username,
      Pool: userPool,
    };

    console.log(userPool);

    var cognitoUser = new CognitoUser(userData);
    let result;
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        console.log(result.accessToken.payload.username);
        console.log("ID TOKEN...");
        console.log(result.getIdToken());
        //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
        let userstring = result.accessToken.payload.username;
        result = {
          result: true,
          username: userstring.charAt(0).toUpperCase() + userstring.slice(1),
        };
        resolve(result);
      },

      onFailure: function (err) {
        alert(err.message || JSON.stringify(err));
        result = { result: false, username: null };
        resolve(result);
      },
    });
  });
};

// Sign out cognito user
const CognitoSignOut = async () => {
  return new Promise((resolve, reject) => {
    var cognitoUser = userPool.getCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
        resolve(false);
      } else {
        if (session.isValid()) {
          console.log("session is still valid - logging out..");
          cognitoUser.signOut();
          resolve(false);
        } else {
          console.log("session is already invalid");
          console.log(session);
          resolve(false);
        }
      }
    });
  });
};

//Check for valid session in cognito
const CognitoCheckSession = async () => {
  console.log(userPool.getCurrentUser());
  return new Promise((resolve, reject) => {
    try {
      var cognitoUser = userPool.getCurrentUser();
      cognitoUser.getSession((err, session) => {
        if (err) {
          let result = { result: false, username: null };
          resolve(result);
          return;
        }
        if (session.isValid()) {
          let userstring = cognitoUser.username;
          let result = {
            result: true,
            username: userstring.charAt(0).toUpperCase() + userstring.slice(1),
          };
          resolve(result);
        } else {
          let result = { result: false, username: null };
          resolve(result);
        }
      });
    } catch (err) {
      console.log(err);
    }
  });
};

// Get current signed in user in cognito
const CognitoGetCurrentUser = () => {
  var cognitoUser = userPool.getCurrentUser();
  //userPool.signUp()
  return cognitoUser;
};

// Sign up user using cognito
const CognitoUserSignUp = (username, password, email, userPool) => {
  var attributeList = [];
  var dataEmail = {
    Name: 'email',
    Value: email,
  };

  var attributeEmail = new CognitoUserAttribute(dataEmail);
  attributeList.push(attributeEmail);
  userPool.signUp(username, password, attributeList, null, function (
    err,
    result
  ) {
    if (err) {
      alert(err.message || JSON.stringify(err));
      return;
    }
    var cognitoUser = result.user;
    console.log('user name is ' + cognitoUser.getUsername());
  });
};

export {
  CognitoLogin as default,
  CognitoSignOut,
  CognitoGetCurrentUser,
  CognitoCheckSession,
  CognitoUserSignUp,
  GetCognitoUserPool
};
