import React, { useState } from "react";
import "./App.css";
import Entry from "./Entry";
import { ContextMenu, MenuItem} from "react-contextmenu";
import moment from 'moment'
import  {
  CognitoGetCurrentUser,
  GetCognitoUserPool,
  CognitoUserSignUp
} from "./loginUtil";
const App = (props) => {

  const [state, setState] = useState({
    currentRightClickedJob: null,
  });

  document.title = "Library";

  const handleContextMenuOpenJobFileClick = () => {
    // when user right-clicks on a project tile (and opens the context menu), sets the current right-clicked-job to its job number, in case the user wants to open this job file via the context menu
    console.log(moment().format("YYYY-MM-DD HH:mm:ss"))
    console.log(state.currentRightClickedJob)
    var cognitoUser = CognitoGetCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else {
        if (session.isValid()) {
          callBackendAPIWithKey("https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/saveprojectnumbertoopenfolder?username=" + props.loggedInUser + "&project_number=" + props.currentRightClickedJob , session.getIdToken().getJwtToken()).then(
            (res) => {
              console.log(res);
            }
          );
        } else {
          console.log(session);
        }
      }
    });
  }

  const handleContextMenuOpenBinderClick = () => {
    // when user right-clicks on a project tile (and opens the context menu), sets the current right-clicked-job to its job number, in case the user wants to open this job file via the context menu
    console.log(moment(new Date).format())
    console.log(props.loggedInUser)
    console.log(state.currentRightClickedJob)
    var cognitoUser = CognitoGetCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else {
        if (session.isValid()) {
          callBackendAPIWithKey("https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/saveprojectnumbertoopenbinder?username=" + props.loggedInUser + "&project_number=" + props.currentRightClickedJob, session.getIdToken().getJwtToken()).then(
            (res) => {
              console.log(res);
            }
          );
        } else {
          console.log(session);
        }
      }
    });
  }

  const handleContextMenuOpenA1TemplateMakerClick = () => {
    // when user right-clicks on a project tile (and opens the context menu), sets the current right-clicked-job to its job number, in case the user wants to open this job file via the context menu
    console.log(moment(new Date).format())
    console.log(props.loggedInUser)
    console.log(state.currentRightClickedJob)
    var cognitoUser = CognitoGetCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else {
        if (session.isValid()) {
          callBackendAPIWithKey("https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/saveprojectnumbertoopentemplatewizard?username=" + props.loggedInUser + "&project_number=" + props.currentRightClickedJob, session.getIdToken().getJwtToken()).then(
            (res) => {
              console.log(res);
            }
          );
        } else {
          console.log(session);
        }
      }
    });
  }

  const handleContextMenuOpenTransmittaWizardClick = () => {
    // when user right-clicks on a project tile (and opens the context menu), sets the current right-clicked-job to its job number, in case the user wants to open this job file via the context menu
    console.log(moment(new Date).format())
    console.log(props.loggedInUser)
    console.log(state.currentRightClickedJob)
    var cognitoUser = CognitoGetCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else {
        if (session.isValid()) {
          callBackendAPIWithKey("https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/saveprojectnumbertoopentransmittalwizard?username=" + props.loggedInUser + "&project_number=" + props.currentRightClickedJob, session.getIdToken().getJwtToken()).then(
            (res) => {
              console.log(res);
            }
          );
        } else {
          console.log(session);
        }
      }
    });
  }

  function setProjectAsComplete(){
    console.log(props.currentRightClickedJob)
    var cognitoUser = CognitoGetCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else {
        if (session.isValid()) {
          callBackendAPIWithKey(
            `https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/setprojectascompleted?projectNumber=${props.currentRightClickedJob}`,
            session.getIdToken().getJwtToken()
          ).then((res) => {
            console.log(res); 
          });
        } else {
          console.log(session);
        }
      }
    });
  }


  const handleAddCognitoUser = () => {

  }

  async function callBackendAPIWithKey(url, authToken) {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
        Authorization: authToken,
      },
    });
    try{
    const body = await response.json();
    return body;
  }
  catch{
    return 0
  }

    // if (response.status !== 200) {
    //   throw Error(body.message);
    // }
    
  }

  const createEntry = (arrayElement) => {
    // creates Entry component and sets props from the input array element (array from AWS lambda response)
    return (
      <Entry
        onRightClick={props.OnRightClick}
        key={arrayElement.value}
        imageUrl={arrayElement.photo_url}
        description={arrayElement.label}
        architect={arrayElement.architect}
        team_leader={arrayElement.team_leader}
        colour={arrayElement.colour}
        type={arrayElement.type}
        handleMenuClick={props.handleMenuClick}
        currentProject={props.currentProject}
        showMoreOrLessOnCards={props.showMoreOrLessOnCards}
      />
    );
  };

  return (
    <div className="content">
      <div className="sticky-spacer"></div>
      <div className="sticky-content">
        <div>
          <dl className="dictionary">
            {props.projectsGridArray.map(createEntry)}
          </dl>
        </div>
      </div>
      <ContextMenu id="same_unique_identifier">
        <MenuItem data={{ foo: "bar" }} onClick={() => handleContextMenuOpenJobFileClick()}>
          Open in explorer
        </MenuItem>
        <MenuItem divider />
        <MenuItem data={{ foo: "bar" }} onClick={() => handleContextMenuOpenBinderClick()}>
          Open binder
        </MenuItem>
        <MenuItem data={{ foo: "bar" }} onClick={() => handleContextMenuOpenA1TemplateMakerClick()}>
          Open A1 template wizard
        </MenuItem>
        <MenuItem data={{ foo: "bar" }} onClick={() => handleContextMenuOpenTransmittaWizardClick()}>
          Open transmittal wizard
        </MenuItem>
        <MenuItem data={{ foo: "bar" }} onClick={() => setProjectAsComplete()}>
          Set project as complete
        </MenuItem>
        {/* <MenuItem data={{ foo: "bar" }} onClick={() => handleAddCognitoUser()}>
          Sign up new user
        </MenuItem> */}
      </ContextMenu>
    </div>
  );
};

export default App;