import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Link, NavLink, withRouter, useHistory } from 'react-router-dom'
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormGroup } from "react-bootstrap";

// Header component (used outside router to be shown on every page)
const Header = (props) => {
  if (!props.isLoggedin) return null;
  return (
    <div className="fixed-header">
      <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand name="projectTitle" href="#home">
          <img
            style={{ backgroundColor: "white", margin: "-10px", marginTop: "-155px", marginBottom: "-150px" }}
            src="https://radial-images.s3-ap-southeast-2.amazonaws.com/WorkMAP-images/Radial+Logo+with+white+padding+around+edge.jpg"
            alt=""
            height="60px"
          ></img>
          {"   "}
        </Navbar.Brand>
        <Form>
          <Form.Group controlId="formBasicEmail" style={{ marginBottom: "-20px" }}>
            <h4><Form.Text id="companyName" onClick={() => props.history.push("/about")} style={{ marginTop: "-10px", color: "#FFFFFF80" }}>
              <small><small>{props.company}</small></small>
            </Form.Text></h4>
            <h2><Form.Label style={{ color: "#FFFFFF80" }}><small><small><small>{props.loggedInUser}</small></small></small></Form.Label></h2>
          </Form.Group>
        </Form>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link onClick={() => props.history.push("/library")}>Library</Nav.Link>
            <NavDropdown title="WorkMAP" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => props.history.push("/stages")}>
                Stages
            </NavDropdown.Item>
              <NavDropdown.Item onClick={() => props.history.push("/projectstaff")}>
                Project staff
            </NavDropdown.Item>
              <NavDropdown.Item onClick={() => props.history.push("/status")}>
                Status
            </NavDropdown.Item>
              <NavDropdown.Item onClick={() => props.history.push("/tasks")}>
                Tasks
            </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Tools" id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => props.history.push("/missingcoords")}>
                Missing coordinates
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => props.history.push("/goradialapplogins")}>
                go.radialapp.com Login List
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => props.history.push("/awss3docs")}>
                AWS S3 Docs
            </NavDropdown.Item>
              <NavDropdown.Item onClick={() => window.location.href = "https://master.d2uzcgj6lkrvtb.amplifyapp.com/"}>
                Elastic search (trial account expired)
            </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={props.onLogout}>
                Log out
            </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Form inline onSubmit={(e) => e.preventDefault()}>
            <div style={{ width: "270px" }}>
              <input
                id="searchTextBox"
                style={{ width: "270px" }}
                placeholder="Search..."
                onChange={props.textboxChanged}
                onFocus={() => props.history.push("/library")}
              ></input>
            </div>
          </Form>
        </Navbar.Collapse>
      </Navbar>
      <div className="subHeader" style={{ backgroundColor: "#343A40", color: "lightgrey", textAlign: "right", paddingRight: "20px", borderTop: "2px white solid" }}>{props.currentProject}</div>
    </div>
  );
};

export default withRouter(Header);
