import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Route,
  Link,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import { } from "react-router-dom";
import App from "./App";
import Header from "./Header";
import Footer from "./Footer";
import Login from "./Login";
import Myob from "./Myob";
import About from "./About";
import Calendar from "./FullCalendarObject";
import GoogleMapComponent from "./GoogleMapComponent";
import Stages from "./Stages";
import Tasks from "./Tasks";
import TasksFunc from "./TasksFunc";
import Todos from "./Todos";
import ProjectStaff from "./ProjectStaff"
import CantileverVideos from "./CantileverVideos";
import WorkMAPGrid from "./WorkMAPGrid"
import AWSLogin, {
  CognitoSignOut,
  CognitoGetCurrentUser,
  CognitoCheckSession,
} from "./loginUtil";
import AWSS3Docs from "./AWSS3Docs";
import MissingLatLon from "./MissingLatLon";
import GoRadialAppLogins from "./GoRadialAppLogins";
import TabulatorTable from "./TabulatorTable";

const Routing = () => {
  const [state, setState] = useState({
    currentProject: localStorage.getItem("currentProject") ? localStorage.getItem("currentProject") : "00000 - default",
    currentProjectNumber: localStorage.getItem("currentProjectNumber") ? localStorage.getItem("currentProjectNumber") : "00000",
    loadoptions: {},
    inputValue: "",
    projectSelectValue: undefined,
    projectsGridArray: [],
    searchLength: null,
    projectsSwiperArray: [],
    top10SwiperArray: [],
    showTop10JobsToggle: true,
    isAuth: false,
    loggedInUser: "Charlie Harris",
    cursor: "default",
    company: "Cantilever",
    currentRightClickedJob: null,
    currentSwiperRightClickedJob: null,
    data: [],
    showMoreOrLessOnCards: "Show more..."
  });

  // set top10 element to prepend onto the footer swiper array to allow user to show/hide top 10 jobs.
  const top10Element = [{ value: "top10", label: "", photo_url: "https://image.shutterstock.com/image-illustration/top-10-on-podium-isolated-260nw-688159822.jpg", type: "top10" }];

  const history = useHistory();

  // set cursor to state cursor variable to allow cursor changes on data fetch etc
  document.body.style.cursor = state.cursor;

  useEffect(() => {
    console.log("useEffect called");
    const fetchData = async () => {
      let result = await CognitoCheckSession();

      setState((prevValue) => {
        return {
          ...prevValue,
          isAuth: result.result,
          loggedInUser: result.username,
        };
      });
    };

    fetchData();
  }, []);

  const handleSwiperRightClick = (e) => {
    e.persist()
    // when user right-clicks on a project tile (and opens the context menu), sets the current right-clicked-job to its job number, in case the user wants to open this job file via the context menu
    console.log(e.target.getAttribute("projectNumber").split(" - ")[0])
    setState((prevValue) => {
      return {
        ...prevValue,
        currentSwiperRightClickedJob: e.target.getAttribute("projectNumber").split(" - ")[0],
      };
    });
  }

  const userHasAuthenticated = (result) => {
    setState((prevValue) => {
      return {
        ...prevValue,
        isAuth: result.result,
        loggedInUser: result.username,
      };
    });
  };

  async function callBackendAPIWithKey(url, authToken) {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
        Authorization: authToken,
      },
    });
    try {
      const body = await response.json();
      return body;
    }
    catch {
      return 0
    }
  }

  const handleRightClick = (e) => {
    e.persist()
    // when user right-clicks on a project tile (and opens the context menu), sets the current right-clicked-job to its job number, in case the user wants to open this job file via the context menu
    setState((prevValue) => {
      return {
        ...prevValue,
        currentRightClickedJob: e.target.parentElement.parentElement.parentElement.childNodes[1].getAttribute("jobinfo").split(" - ")[0],
      };
    });
  }

  const handleHeaderTextbox = (event) => {
    let eventLength = event.target.value.length
    // event to pass to textbox onChange event in Header component - set state to the projects that match the search criteria. The tiles will be rendered from this state change as a result.
    var cognitoUser = CognitoGetCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else {
        if (session.isValid()) {
          if (event.target.value.substring(0, 1) === "@") {
            callBackendAPIWithKey(
              "https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/gettools?searchTerm=" +
              event.target.value,
              session.getIdToken().getJwtToken()
            ).then((res) => {
              setState((prevValue) => {
                return {
                  ...prevValue,
                  projectsGridArray: res,
                };
              });
              console.log(res);
            });
          } else {
            callBackendAPIWithKey(
              "https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getprojects?searchTerm=" +
              event.target.value.replace(" + ", "%20%2B%20").replace("+", "%2B"),
              session.getIdToken().getJwtToken()
            ).then((res) => {
              if (document.getElementById("searchTextBox").value.length === eventLength) {
                setState((prevValue) => {
                  return {
                    ...prevValue,
                    projectsGridArray: res,
                    projectsSwiperArray: res,
                  };
                });
                console.log(res);
              }
            });
          }
        } else {
          console.log(session);
        }
      }
    });
  };

  function handleMenuClick(url, isProject) {
    // event to pass to the Header Navbar menu (for items to render tiled images in the grid format such as project staff, tasks and disciplines etc).
    var cognitoUser = CognitoGetCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else {
        if (session.isValid()) {
          callBackendAPIWithKey(url, session.getIdToken().getJwtToken()).then(
            (res) => {
              console.log(res);
              if (isProject) {
                setState((prevValue) => {
                  return {
                    ...prevValue,
                    currentProject: isProject,
                    currentProjectNumber: isProject.split(" ")[0]
                  };
                });
                localStorage.setItem("currentProject", isProject)
                localStorage.setItem("currentProjectNumber", isProject.split(" ")[0])
              } else {
                setState((prevValue) => {
                  return {
                    ...prevValue,
                    projectsGridArray: res,
                  };
                });
              }
            }
          );
        } else {
          console.log(session);
        }
      }
    });
  }

  // update project number state when project number changed from thumbnail
  const handleCurrentProjectChangeFromThumbnail = (newProject) => {
    setState((prevValue) => {
      return {
        ...prevValue,
        currentProject: newProject,
        currentProjectNumber: newProject.split(" ")[0]
      };
    });
    localStorage.setItem("currentProject", newProject)
    localStorage.setItem("currentProjectNumber", newProject.split(" ")[0])
  }

  // update showMoreOrLessOnCards state when show more/less toggle clicked on
  const handleShowMoreOrLessChangeFromFooter = (showMoreOrLess) => {
    console.log("project number being changed from Thumbnail")
    console.log(showMoreOrLess)
    setState((prevValue) => {
      return {
        ...prevValue,
        showMoreOrLessOnCards: showMoreOrLess === "Show less..." ? "Show more..." : "Show less...",
      };
    });
  }

  // update showTop10JobsToggle state when top10 toggle clicked on
  const handleTop10JobsChangeFromThumbnail = () => {
    setState((prevValue) => {
      return {
        ...prevValue,
        showTop10JobsToggle: !state.showTop10JobsToggle
      };
    });

    if (state.showTop10JobsToggle) {
      var cognitoUser = CognitoGetCurrentUser();
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log(err);
        } else {
          if (session.isValid()) {
            callBackendAPIWithKey("https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getlatest10jobs", session.getIdToken().getJwtToken()).then(
              (res) => {
                console.log(res);
                setState((prevValue) => {
                  return {
                    ...prevValue,
                    top10SwiperArray: res,
                  };
                });

              }
            );
          } else {
            console.log(session);
          }
        }
      });
    }
    else {
      setState((prevValue) => {
        return {
          ...prevValue,
          top10SwiperArray: [],
        };
      });
    }
  }



  const sendToAWS = (url, dataToChange) => {
    var cognitoUser = CognitoGetCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
      } else {
        if (session.isValid()) {
          callBackendAPIWithKey(
            url,
            session.getIdToken().getJwtToken()
          ).then((res) => {
            console.log(res);

            if (dataToChange) {
              setState((prevValue) => {
                return {
                  ...prevValue,
                  [dataToChange]: res,
                };
              });
            }
          });
        } else {
          console.log(session);
        }
      }
    });
  }


  const cellEdited = (cell) => {

    console.log(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagedates?projectNumber=${state.currentProjectNumber}&column=${cell._cell.column.field}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)


    if (cell._cell.column.field.includes("date")) {
      if (cell._cell.value !== "Invalid date") {
        sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagedates?projectNumber=${state.currentProjectNumber}&column=${cell._cell.column.field}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)
      }
      else {
        cell._cell.table.undo();
      }
    }
    else if (cell._cell.column.field.includes("leader") || cell._cell.column.field.includes("staff")) {
      sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagestatusorstaff?projectNumber=${state.currentProjectNumber}&column=${cell._cell.column.field}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)
    }
    else {
      // Only automatically set adjacent date cell to today's date if the status cell is set to 'Completed'
      if (!cell._cell.row.data[cell._cell.column.field + '_date'] && cell._cell.value === "Completed") {
        var date_column = cell._cell.column.field + '_date';
        console.log(cell._cell.value)
        sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagedates?projectNumber=${state.currentProjectNumber}&column=${date_column}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)
      }
      sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/updatestagestatusorstaff?projectNumber=${state.currentProjectNumber}&column=${cell._cell.column.field}&value=${cell._cell.value}&disciplineLetter=${cell._cell.row.data['Discipline'].split(" ")[0]}`)
      sendToAWS(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getstages?searchTerm=${state.currentProjectNumber}`, "data")
    }
  }



  //handle user logout (ser isAuth state to false)
  const handleLogOut = async (event) => {
    console.log(event.target.value);
    await CognitoSignOut();
    setState((prevValue) => {
      return {
        ...prevValue,
        isAuth: false,
        projectsGridArray: [],
        projectsSwiperArray: []
      };
    });
  };

  let routes = (
    <Router>
      <div>
        <Header
          isLoggedin={state.isAuth}
          loggedInUser={state.loggedInUser}
          textboxChanged={handleHeaderTextbox}
          onLogout={handleLogOut}
          onMenuClick={handleMenuClick}
          currentProject={state.currentProject}
          company={state.company}
        />
        {state.isAuth ? (
          <Switch>
              <Route
              exact
              path="/library"
              render={(props) => (
                <App
                  {...props}
                  isLoggedin={state.isAuth}
                  loggedInUser={state.loggedInUser}
                  projectsGridArray={state.projectsGridArray}
                  handleMenuClick={handleMenuClick}
                  currentProject={state.currentProject}
                  currentProjectNumber={state.currentProjectNumber}
                  OnRightClick={handleRightClick}
                  currentRightClickedJob={state.currentRightClickedJob}
                  showMoreOrLessOnCards={state.showMoreOrLessOnCards}
                />
              )}
            />
            <Route
              exact
              path="/about"
              render={(props) => (
                <About
                  {...props}
                  isLoggedin={state.isAuth}
                  loggedInUser={state.loggedInUser}
                  projectsGridArray={state.projectsGridArray}
                  handleMenuClick={handleMenuClick}
                  currentProject={state.currentProject}
                  OnRightClick={handleRightClick}
                  currentRightClickedJob={state.currentRightClickedJob}
                />
              )}
            />
            <Route
              exact
              path="/cantilevervideos"
              render={(props) => (
                <CantileverVideos
                  {...props}
                  isLoggedin={state.isAuth}
                  loggedInUser={state.loggedInUser}
                  projectsGridArray={state.projectsGridArray}
                  handleMenuClick={handleMenuClick}
                  currentProject={state.currentProject}
                  OnRightClick={handleRightClick}
                  currentRightClickedJob={state.currentRightClickedJob}
                />
              )}
            />
            <Route path="/myob" component={Myob} />
            <Route path="/calendar" component={Calendar} />
            <Route
              exact
              path="/map"
              render={(props) => (
                <GoogleMapComponent
                  {...props}
                  isMarkerShown
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCduJD7Q_WrNcWc5WcPBMwu5ymRj_oKliQ&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div style={{ height: `900px`, paddingTop: `25px` }} />
                  }
                  mapElement={
                    <div style={{ height: `100%`, overflowY: "hidden" }} />
                  }
                />
              )}
            />
            <Route
              exact
              path="/stages"
              render={(props) => (
                <Stages
                  {...props}
                  currentProjectNumber={state.currentProjectNumber}
                  currentProject={state.currentProject}
                />
              )}
            />
            <Route
              exact
              path="/tasks"
              render={(props) => (
                <Tasks
                  {...props}
                  currentProjectNumber={state.currentProjectNumber}
                  currentProject={state.currentProject}
                />
              )}
            />
            <Route
              exact
              path="/tasksfunc"
              render={(props) => (
                <TasksFunc
                  {...props}
                  currentProjectNumber={state.currentProjectNumber}
                  currentProject={state.currentProject}
                />
              )}
            />
            <Route
              exact
              path="/todos"
              render={(props) => (
                <Todos
                  {...props}
                  currentProjectNumber={state.currentProjectNumber}
                  currentProject={state.currentProject}
                />
              )}
            />
            <Route
              exact
              path="/projectstaff"
              render={(props) => (
                <ProjectStaff
                  {...props}
                  currentProjectNumber={state.currentProjectNumber}
                  currentProject={state.currentProject}
                />
              )}
            />
            <Route
              exact
              path="/workmapgrid"
              render={(props) => (
                <WorkMAPGrid
                  {...props}
                  isLoggedin={state.isAuth}
                  loggedInUser={state.loggedInUser}
                  projectsGridArray={state.projectsGridArray}
                  handleMenuClick={handleMenuClick}
                  currentProject={state.currentProject}
                  OnRightClick={handleRightClick}
                  currentRightClickedJob={state.currentRightClickedJob}
                />
              )}
            />
            <Route
              exact
              path="/awss3docs"
              render={(props) => (
                <AWSS3Docs/>
              )}
            />
            <Route
              exact
              path="/goradialapplogins"
              render={(props) => (
                <TabulatorTable/>
              )}
            />
            <Route
              exact
              path="/missingcoords"
              render={(props) => (
                <MissingLatLon />
              )}
            />
            <Redirect from="/login" to="/library"></Redirect>
            <Redirect from="*" to="/library"></Redirect>
          </Switch>
        ) : (
            <Switch>
              <Route
                exact
                path="/login"
                render={(props) => (
                  <Login {...props} userHasAuthenticated={userHasAuthenticated} />
                )}
              />
              <Route
                exact
                path="*"
                render={(props) => (
                  <Login {...props} userHasAuthenticated={userHasAuthenticated} />
                )}
              />
            </Switch>
          )}
        <Footer
          isLoggedin={state.isAuth}
          showTop10JobsToggle={state.showTop10JobsToggle}
          top10Element={state.top10Element}
          projectsSwiperArray={state.projectsSwiperArray}
          top10SwiperArray={state.top10SwiperArray}
          handleSwiperRightClick={handleSwiperRightClick}
          handleCurrentProjectChangeFromThumbnail={
            handleCurrentProjectChangeFromThumbnail
          }
          handleTop10JobsChangeFromThumbnail={
            handleTop10JobsChangeFromThumbnail
          }
          handleShowMoreOrLessChangeFromFooter={
            handleShowMoreOrLessChangeFromFooter
          }
          loggedInUser={state.loggedInUser}
          currentSwiperRightClickedJob={state.currentSwiperRightClickedJob}
          showMoreOrLessOnCards={state.showMoreOrLessOnCards}
        />
      </div>
    </Router>
  );
  return <div>{routes}</div>;
};

export default Routing;

ReactDOM.render(<Routing />, document.getElementById("root"));
