import React from "react";

// Video component for use in map function
function Video(props) {
  return (
    <div className="term">
      <dt>
        <video
          width="220"
          height="180"
          controls="controls"
          src={props.videoUrl}
        >
          Your browser does not support the HTML5 Video element.
        </video>
      </dt>
      <dd
        width="220"
        jobinfo={props.description}
      >
        {props.description}
      </dd>
    </div>
  );
}

export default Video;