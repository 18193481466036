import React, { useState, useEffect } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const GoogleMapComponent = withScriptjs(withGoogleMap((props) =>(
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: -34.397, lng: 150.644 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: -33.8568, lng: 151.2153 }} />}
  </GoogleMap>
))
)
export default GoogleMapComponent;
